#video {
    --eid-a-default: #8ab89c;
    --eid-a-hover: #8ab89c;
    --eid-a-active: #8ab89c;    
    height: 900px;    
  }
  
  #video .eid-button {
      background-color: #8ab89c;
      border-color: #8ab89c;
  
  }
  
  #video .eid-detection-roi {
      border-color: #8ab89c;
  }

  /* #video .eid-title{
    display: none;
  } */